<template>
  <v-simple-table class="ma-4" dense>
    <template v-slot:default>
      <tbody>
        <tr></tr>
        <tr>
          <td>{{ $t("ID") }}:</td>
          <td>{{ item.id }}</td>
        </tr>
        <tr>
          <td>{{ $t("active") }}:</td>
          <td>
            <v-icon v-if="item.active">mdi-check-box-outline</v-icon>
            <v-icon v-if="!item.active">mdi-checkbox-blank-outline</v-icon>
          </td>
        </tr>
        <tr>
          <td>{{ $t("name") }}:</td>
          <td>{{ item.name }}</td>
        </tr>
        <tr>
          <td>{{ $t("reporting email") }}:</td>
          <td>
            <span v-if="item.email">{{ item.email }}</span>
            <span v-else class="grey--text">{{ defaults.email }}</span>
          </td>
        </tr>
        <tr>
          <td>{{ $t("report success") }}:</td>
          <td>
            <v-icon v-if="item.report_success === true"
              >mdi-check-box-outline</v-icon
            >
            <v-icon v-else-if="item.report_success === false"
              >mdi-checkbox-blank-outline</v-icon
            >
            <v-icon
              v-else-if="defaults.report_success === true"
              color="inactive"
              >mdi-check-box-outline</v-icon
            >
            <v-icon v-else color="inactive">mdi-checkbox-blank-outline</v-icon>
          </td>
        </tr>
        <tr>
          <td>{{ $t("report fail") }}:</td>
          <td>
            <v-icon v-if="item.report_fail === true">
              mdi-check-box-outline
            </v-icon>
            <v-icon v-else-if="item.report_fail === false">
              mdi-checkbox-blank-outline
            </v-icon>
            <v-icon v-else-if="defaults.report_fail === true" color="inactive">
              mdi-check-box-outline
            </v-icon>
            <v-icon v-else color="inactive">mdi-checkbox-blank-outline</v-icon>
          </td>
        </tr>
        <tr>
          <td>{{ $t("check interval") }}:</td>
          <td>
            <span v-if="item.check_interval !== null">
              {{ item.check_interval }}
            </span>
            <span v-else class="grey--text">{{ defaults.check_interval }}</span>
          </td>
        </tr>
        <tr>
          <td>{{ $t("number of backups") }}:</td>
          <td>{{ item.backup_count }}</td>
        </tr>
        <tr>
          <td>{{ $t("first backup") }}:</td>
          <td>{{ formatDateTime(item.backup_first) }}</td>
        </tr>
        <tr>
          <td>{{ $t("last backup") }}:</td>
          <td>{{ formatDateTime(item.backup_last) }}</td>
        </tr>
        <tr>
          <td>{{ $t("last size") }}:</td>
          <td>{{ prettyBytes(item.backup_last_size) }} Byte</td>
        </tr>
        <tr>
          <td>{{ $t("max. size") }}:</td>
          <td>{{ prettyBytes(item.backup_size_max) }} Byte</td>
        </tr>
        <tr>
          <td>{{ $t("min. size") }}:</td>
          <td>{{ prettyBytes(item.backup_size_min) }} Byte</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import formatDateTime from "@/utils/mixins/formatDateTime";
import prettyBytes from "@/utils/mixins/prettyBytes";

export default {
  name: "VeeamBackupDetails",
  props: {
    item: {
      type: Object,
      required: true,
    },
    defaults: {
      type: Object,
      required: true,
    },
  },
  mixins: [formatDateTime, prettyBytes],
  data: () => ({}),
};
</script>

<i18n>
{
  "en": {
   "ID": "ID",
   "active": "Active",
   "name": "Name",
   "reporting email": "Reporting email",
   "report success": "Report success",
   "report fail": "Report fail",
   "check interval": "Check interval",
   "number of backups": "Number of backups",
   "first backup": "First backup",
   "last backup": "Last backup",
   "last size": "Last size",
   "max. size": "Max. size",
   "min. size": "Min. size",
   "default": "Default"
  },
  "de": {
   "ID": "ID",
   "active": "Aktiv",
   "name": "Name",
   "reporting email": "Report Email",
   "report success": "Reporte Erfolg",
   "report fail": "Reporte Fehler",
   "check interval": "Check Interval",
   "number of backups": "Anzahl Backups",
   "first backup": "Erstes Backup",
   "last backup": "Letztes Backup",
   "last size": "Letzte Grösse",
   "max. size": "Max. Grösse",
   "min. size": "Min. Grösse",
   "default": "Default"
  }
}
</i18n>
