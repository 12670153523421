<template>
  <v-card elevation="1">
    <validation-observer ref="obs" v-slot="{ errors, invalid }">
      <v-form onSubmit="return false;">
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <validation-provider
                vid="language"
                :name="$t('language')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-select
                  v-model="defaults.language"
                  :items="languages"
                  item-text="title"
                  item-value="code"
                  :label="$t('language')"
                  :error-messages="errors"
                  :hint="$t('change report language')"
                  :class="classes"
                  persistent-hint
                  menu-props="auto"
                >
                  <template v-slot:item="{ item }">
                    <div>
                      <lang-flag :iso="item.code" v-bind:squared="false" />
                      <span class="ml-4">{{ item.title }}</span>
                    </div>
                  </template>
                  <template v-slot:prepend>
                    <lang-flag
                      v-if="defaults.language"
                      :iso="defaults.language"
                      v-bind:squared="false"
                      class="mr-2"
                    />
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <email-list-input
                :label="$t('email')"
                :hint="$t('multiple email addresses possible')"
                v-model="email"
                max-length="1024"
                rules="required"
                array
                multiple
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <validation-provider
                vid="report_success"
                :name="$t('report success')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-switch
                  v-model="defaults.report_success"
                  name="report_success"
                  :label="$t('report success')"
                  :error-messages="errors"
                  :success="dirty && valid"
                  :class="classes"
                />
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider
                vid="report_fail"
                :name="$t('report fail')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-switch
                  v-model="defaults.report_fail"
                  name="report_fail"
                  :label="$t('report fail')"
                  :error-messages="errors"
                  :success="dirty && valid"
                  :class="classes"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="loading" @click="$emit('defaultsCanceled')" text>
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="loading || invalid"
            @click="saveDefaults()"
            text
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import EmailListInput from "@/components/basics/EmailListInput";
import showErrors from "@/utils/mixins/showErrors";

export default {
  name: "VeeamBackupDefaults",
  mixins: [showErrors],
  components: {
    EmailListInput,
  },
  props: {
    customerNumber: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      loading: false,
      defaults: {},
      email: [],
      languages: [
        { code: "en", title: this.$i18n.t("english") },
        { code: "de", title: this.$i18n.t("german") },
      ],
    };
  },
  watch: {
    "$props.customerNumber": function () {
      this.getDefauls();
    },
    email: function () {
      this.defaults.email = this.email.join();
    },
  },
  methods: {
    getDefauls() {
      var that = this;
      if (this.customerNumber != null) {
        this.loading = true;
        this.$http
          .get("services/veeam/customer/" + this.customerNumber.toString())
          .then((response) => {
            that.defaults = response.data;
            that.email = [];
            that.email.push(response.data.email);
          })
          .catch((error) => {
            if (error.invalid()) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(function () {
            that.loading = false;
          });
      }
    },
    saveDefaults() {
      var that = this;
      this.loading = true;
      this.$http
        .patch(
          "services/veeam/customer/" + this.customerNumber.toString(),
          this.defaults
        )
        .then((response) => {
          this.defaults = response.data;
          that.email = [];
          that.email.push(response.data.email);
          this.$emit("defaulsUpdated");
        })
        .catch((error) => {
          if (error.invalid()) {
            that.$refs.obs.setErrors(error.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          } else
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.getDefauls();
  },
};
</script>

<i18n>
{
  "en": {
    "email": "Email",
    "language": "Language",
    "report success": "Report success",
    "report fail": "Report fail",
    "cancel": "Cancel",
    "save": "Save",
    "change report language": "Change report language",
    "multiple email addresses possible": "It's possible to have multiple reporting addresses"
  },
  "de": {
    "email": "Email",
    "language": "Sprache",
    "report success": "Reporte Erfolg",
    "report fail": "Reporte Fehler",
    "cancel": "Abbruch",
    "save": "Speichern",
    "change report language": "Ändert Report Sprache",
    "multiple email addresses possible": "Es sind mehrere Reporting Email Adressen möglich"
  }
}
</i18n>
