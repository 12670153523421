<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("Veeam VMs") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              defaultDialog = true;
              expanded = [];
            "
            :disabled="loading || defaultDialog"
            icon
          >
            <v-icon>mdi-tune</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("change default settings") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="vms"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        :search="search"
        show-expand
        single-expand
        sort-by="name"
        :expanded.sync="expanded"
        @item-expanded="defaultDialog = false"
        :hide-default-footer="vms.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
      >
        <template v-slot:top>
          <div>
            <v-row v-if="!defaultDialog">
              <v-col cols="12">
                <v-text-field
                  v-model="search"
                  append-outer-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  :clearable="!isMobile"
                  v-bind:label="$t('search VM')"
                  single-line
                  hide-details
                  @click:clear="search = ''"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-expand-transition>
              <veeam-backup-defaults
                class="mb-4"
                v-if="defaultDialog"
                :customerNumber="$store.state.session.number"
                @defaulsUpdated="
                  defaultDialog = false;
                  getDefaults();
                "
                @defaultsCanceled="defaultDialog = false"
              />
            </v-expand-transition>
          </div>
        </template>

        <template v-slot:item.email="{ item }">
          <v-edit-dialog
            :return-value.sync="item.email"
            @save="saveVm(item)"
            :cancelText="$t('cancel')"
            :saveText="$t('save')"
            large
          >
            <div class="editable">
              <v-icon small>mdi-pencil-outline</v-icon>
              <div>
                <span v-if="item.email">{{ item.email }}</span>
                <span v-else class="grey--text">{{ defaults.email }}</span>
              </div>
            </div>

            <template v-slot:input>
              <validation-provider
                vid="email"
                :name="$t('email')"
                rules="required|email_list|max:1024"
                v-slot="{ errors, valid, dirty }"
              >
                <v-text-field
                  v-model="item.email"
                  name="email"
                  :label="$t('reporting email')"
                  :rules="[max1024chars]"
                  :error-messages="errors"
                  :success="dirty && valid"
                  autofocus
                  :clearable="!isMobile"
                />
              </validation-provider>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.report_success="{ item }">
          <v-edit-dialog
            :return-value.sync="item.report_success"
            @save="saveVm(item)"
            :cancelText="$t('cancel')"
            :saveText="$t('save')"
            large
          >
            <div class="editable">
              <v-icon small>mdi-pencil-outline</v-icon>
              <div>
                <v-icon v-if="item.report_success === true"
                  >mdi-check-box-outline</v-icon
                >
                <v-icon v-else-if="item.report_success === false"
                  >mdi-checkbox-blank-outline</v-icon
                >
                <v-icon
                  v-else-if="defaults.report_success === true"
                  color="inactive"
                  >mdi-check-box-outline</v-icon
                >

                <v-icon v-else color="inactive"
                  >mdi-checkbox-blank-outline</v-icon
                >
              </div>
            </div>

            <template v-slot:input>
              <v-radio-group
                mandatory
                v-model="item.report_success"
                name="report_success"
                :label="$t('report success')"
              >
                <v-radio :label="$t('default')" :value="null" />
                <v-radio :label="$t('on')" :value="true" />
                <v-radio :label="$t('off')" :value="false" />
              </v-radio-group>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.report_fail="{ item }">
          <v-edit-dialog
            :return-value.sync="item.report_fail"
            @save="saveVm(item)"
            large
            :cancelText="$t('cancel')"
            :saveText="$t('save')"
          >
            <div class="editable">
              <v-icon small>mdi-pencil-outline</v-icon>
              <div>
                <v-icon v-if="item.report_fail === true"
                  >mdi-check-box-outline</v-icon
                >
                <v-icon v-else-if="item.report_fail === false"
                  >mdi-checkbox-blank-outline</v-icon
                >
                <v-icon
                  v-else-if="defaults.report_fail === true"
                  color="inactive"
                  >mdi-check-box-outline</v-icon
                >
                <v-icon v-else color="inactive"
                  >mdi-checkbox-blank-outline</v-icon
                >
              </div>
            </div>

            <template v-slot:input>
              <v-radio-group
                mandatory
                v-model="item.report_fail"
                name="report_fail"
                :label="$t('report fail')"
              >
                <v-radio :label="$t('default')" :value="null" />
                <v-radio :label="$t('on')" :value="true" />
                <v-radio :label="$t('off')" :value="false" />
              </v-radio-group>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.backup_first="{ item }">
          <div class="monospace">
            {{
              item.backup_first === null
                ? ""
                : formatDateTime(item.backup_first)
            }}
          </div>
        </template>

        <template v-slot:item.backup_last="{ item }">
          <div class="monospace">
            {{
              item.backup_last === null ? "" : formatDateTime(item.backup_last)
            }}
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <veeam-backup-details :item="item" :defaults="defaults" />
          </td>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="
                  $router.push({
                    name: 'veeam-backup-details',
                    params: { backupProductId: item.id },
                  })
                "
                text
                small
                icon
              >
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("show backups") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDateTime from "@/utils/mixins/formatDateTime";
import VeeamBackupDetails from "@/components/services/VeeamBackupDetails";
import VeeamBackupDefaults from "@/components/services/VeeamBackupDefaults";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "VeeamBackup",
  mixins: [formatDateTime, isMobile],
  components: {
    VeeamBackupDetails,
    VeeamBackupDefaults,
  },
  data: function() {
    return {
      vms: [],
      loading: false,
      defaultDialog: false,
      search: "",
      expanded: [],
      defaults: {},
      max1024chars: (v) =>
        v === null || v.length <= 1024 || this.$i18n.t("Input too long!"),
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("VM"),
          value: "name",
          filterable: true,
        },
        {
          text: this.$i18n.t("email"),
          value: "email",
        },
        {
          text: this.$i18n.t("success"),
          value: "report_success",
        },
        {
          text: this.$i18n.t("fails"),
          value: "report_fail",
        },
        {
          text: this.$i18n.t("first backup"),
          value: "backup_first",
        },
        {
          text: this.$i18n.t("last backup"),
          value: "backup_last",
        },
        { text: "", value: "data-table-expand" },
        { text: "", value: "action", sortable: false },
      ];
    },
  },
  watch: {
    "$store.state.session": function() {
      this.vms = [];
      this.getVms();
    },
  },
  methods: {
    getVms: function() {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/veeam/vm")
        .then((response) => {
          that.vms = response.data;
        })
        .catch((err) => {
          if (err.invalid()) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    saveVm(item) {
      var that = this;
      var data = {
        email: item.email || null,
        report_success: item.report_success === 0 ? null : item.report_success,
        report_fail: item.report_fail === 0 ? null : item.report_fail,
      };
      this.$http
        .patch("services/veeam/vm/" + item.id, data)
        .then((response) => {
          item = response.data;
        })
        .catch((err) => {
          if (err.invalid()) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        });
    },
    getDefaults() {
      var that = this;
      if (this.$store.getters.isReady) {
        this.loading = true;
        this.$http
          .get("services/veeam/customer/" + this.$store.state.session.number)
          .then((response) => {
            that.defaults = response.data;
          })
          .catch((error) => {
            if (error.invalid()) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(function() {
            that.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getVms();
    this.getDefaults();
  },
};
</script>

<style scoped>
.editable {
  display: flex;
  align-items: center;
}
</style>

<i18n>
{
  "en": {
    "defaults": "Defaults",
    "Veeam VMs": "Veeam VMs",
    "report success": "Report success",
    "report fail": "Report fail",
    "reporting email": "Report email",
    "first backup": "First backup",
    "last backup": "Last backup",
    "last backup size": "Last backup size",
    "number": "Number",
    "VM": "VM",
    "default": "Default",
    "on": "On",
    "off": "Off",
    "save": "Save",
    "cancel": "Cancel",
    "fails": "Fails",
    "success": "Success",
    "email": "Email",
    "reporting email": "Reporting email",
    "search VM": "Search VM",
    "Input too long!": "Input too long!",
    "change default settings": "Change default settings",
    "show backups": "Show backups"
  }, 
  "de": {
    "defaults": "Defaults",
    "Veeam VMs": "Veeam VMs",
    "report success": "reporte Erfolg",
    "report fail": "reporte Fehler",
    "reporting email": "Report Email",
    "first backup": "erstes Backup",
    "last backup": "letztes Backup",
    "last backup size": "Grösse letztes Backup",
    "number": "Anzahl",
    "VM": "VM",
    "default": "Default",
    "on": "an",
    "off": "aus",
    "save": "speichern",
    "cancel": "abbrechen",
    "fails": "Fehler",
    "success": "Erfolg",
    "email": "Email",
    "reporting email": "Report Email",
    "search VM": "VM suchen",
    "Input too long!": "Eingabe zu lang!",
    "change default settings": "Default Einstellungen ändern",
    "show backups": "zeige Backups"
  }
}
</i18n>
